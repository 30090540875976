// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue/dist/vue.js'
import App from './App'
import VueRouter from 'vue-router'
import routes from '@/vue/router/routes'
import store from './vue/store/index'
import VueResource from 'vue-resource'
import { mapActions, mapGetters } from 'vuex'
import './vue/filters'
import UUID from 'vue-uuid'
import VueQRCodeComponent from 'vue-qrcode-component'
import es from 'vee-validate/dist/locale/es';
import VeeValidate, { Validator } from 'vee-validate';

Vue.use(UUID)


Vue.use(VueRouter);
Vue.use(VueResource)

const router = new VueRouter({
  routes: routes,
});

// Moment translate
const moment = require('moment')
require('moment/locale/es')
Vue.use(require('vue-moment'), {
  moment
})

Vue.use(VeeValidate);
Validator.localize('es', es);

Vue.http.options.root = 'https://solutions.fusepong.com/api/';
// Vue.http.options.root = 'https://staging.fusepong.com/api/';
// Vue.http.options.root = 'http://192.168.0.22:4000/api/';
// Vue.http.options.root = 'http://192.168.0.78:3000/api/';

Vue.http.headers.common['Access-Control-Allow-Origin'] = '*'
Vue.http.headers.common['Accept-Language'] = 'es'
Vue.http.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, PATCH, PUT, DELETE, OPTIONS'
Vue.http.headers.common['Access-Control-Allow-Headers'] = 'Origin, Content-Type, Authentication, Accept-Language'
Vue.http.headers['Access-Control-Max-Age'] = '1728000'




Vue.mixin({
  methods: {
    ...mapActions([
      "updateCurrentCompany"
    ]),
    ...mapGetters([
      "getCurrentCompany"
    ]),
    encrypt(data) {
      var CryptoJS = require("crypto-js");
      var payload = "G7qeaR2Yb4DAgk92ZQHdjQ==|gfYvWJ0vVdX142/xpsGPW8Fsgc/dGeQPFtaazcUMjQo=|VF/bl6Ae5CVTtQTPCrmAgQ==".split("|");
      var iv = payload[0], hmac = payload[1], cipherText = payload[2];
      var passphraseDgst = CryptoJS.SHA256("ImAwesomeAndHappy").toString();
      var key = passphraseDgst;
      var encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        CryptoJS.enc.Hex.parse(passphraseDgst),
        {iv:CryptoJS.enc.Base64.parse(iv)}
      );
      return encrypted
    },

    decrypt_info(data){
      var CryptoJS = require("crypto-js");
      var iv = CryptoJS.enc.Base64.parse("kT+uMuPwUk2LH4cFbK0GiA==")
      var key = CryptoJS.enc.Hex.parse("6476b3f5ec6dcaddb637e9c9654aa687")
      let decrypted_value = CryptoJS.AES.decrypt(data,  key,  {mode: CryptoJS.mode.CBC,  iv : iv}).toString(CryptoJS.enc.Utf8)
      return JSON.parse(decrypted_value);
    },
    convertDaytimeFormatToHour(hour){
      var arr=hour.split(":")
      let hours=Number(arr[0])
      let secondArr=arr[1].split(" ")
      var minutes=secondArr[0]
      var amOrPm=secondArr[1]
      var finalHour=[hours,minutes]

      if(amOrPm=="pm" && hours!=12){
        finalHour[0]= (Number(hours)+12)%24
      }else if(hours==12 && amOrPm=="am" ){
        finalHour[0]=0
      }
      return finalHour[0].toString().padStart(2,"0")+":"+finalHour[1].toString().padStart(2,"0");
    },
    convertDurationFormat(time){
      if (time!==null && time!==undefined){
        let arr=time.split(" h ")
        return arr[0]+":"+arr[1].toString().padStart(2,"0");
      }
    },
    addHours(hour1,hour2){
      if (hour1!==null && hour1!==undefined && hour2!==null && hour2!==undefined ){
        let arr1=hour1.split(":");
        let arr2=hour2.split(":");
        return this.formatDay(Number(arr1[0])+Number(arr2[0]),Number(arr1[1])+Number(arr2[1]))
      }
    },
    firstIsGreaterThanSecond(hour1,hour2){
      if (hour1!==null && hour1!==undefined && hour2!==null && hour2!==undefined ){
        let arr1=hour1.split(":");
        let arr2=hour2.split(":");
        let ans=false;
        if( Number(arr1[0])>Number(arr2[0]) || (Number(arr1[0]) == Number(arr2[0]) && Number(arr1[1]) > Number(arr2[1]))){
          ans=true
        }
        return ans
      }
    },
    formatDay(hour,minutes){
      if(minutes>=60){
        hour+=(minutes/60)
        minutes=minutes%60
      }
      if(hour>=24){
        hour=hour%24
      }
      return hour.toString().padStart(2,"0")+":"+minutes.toString().padStart(2,"0");
    },
    shortDate(date){
      let new_date =  date.substring(0,date.indexOf('T'))
      return new_date
    },
    buildDeviceId(){
      var uuid
      if(localStorage.device_id != undefined &&
        localStorage.device_id != null &&
        localStorage.device_id != '' &&
        localStorage.device_id != 'null'){
        uuid = localStorage.device_id
      } else {
        uuid = this.$uuid.v1()
        localStorage.device_id = uuid
      }
      console.log("DEVICE ID!!!!!!!!!!!!!!!!!!!!!!");
      console.log(uuid);
      this.updateDeviceId(uuid);
      return uuid
    },
  }
})


/* eslint-disable no-new */
new Vue({
  el: '#app',
  router: router,
  components: { App },
  template: '<App/>',
  store: store
})
