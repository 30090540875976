
import Upload from '@/components/shared/Upload.vue'
import CodeQr from "@/components/views/invitations/CodeQr.vue";
import AccessRequirement from "@/components/views/invitations/AccessRequirement.vue";
import Success from "@/components/views/Success.vue";
import Denied from "@/components/views/Denied.vue";

export default [
  // {path: '/', component: Upload, name: 'root'},
  {path: '/', component: AccessRequirement, name: 'root'},
  {path: '/success', component: Success, name: 'root'},
  {path: '/denied', component: Denied, name: 'root'},
]
