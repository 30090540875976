<template>
  <article class="form_modal__fair access_requirement">
    <loader v-if="loader"></loader>
    <section class="form_modal_fair__container_video fair_invitation fair_invitation_right">
      <section class="access_requirement__company_logo">
        <img src="https://fusepongsolutions.s3.us-west-2.amazonaws.com/Logofuseaccess.png" alt="">
      </section>
      <section class="access_requirement__virtual_guard">
        <!-- <img src="" alt=""> -->
        <virtual-guard-logo></virtual-guard-logo>
      </section>
      <div class="fair_invitation__content">
        <div class="fair_invitation__content--right">
          <h3>Solicita tu ingreso</h3>
          <div v-if="company_invitation_form_vehicle_data">
            <label>No. de identificación *</label>
            <input
              v-model="mail.rut"
              type="text"
              name="rut"
              placeholder="Escribe tu número de identificación"
              v-validate="'required'">
            <span
              style="color:red; font-size:12px"
              v-show="errors.has('rut') && show_errors"
              class="showError">El campo número de identificación es requerido.</span>
          </div>
          <div v-if="company_invitation_form_vehicle_data && vehicular_type_location">
            <label>Tipo de vehículo *</label>
            <select class="" name="vehicle_type" v-model="mail.vehicle_type">
              <option value="" disabled selected>Selecciona el tipo de vehículo</option>
              <option value="15">Carro</option>
              <option value="16">Moto</option>
            </select>
            <span
              style="color:red; font-size:12px"
              v-show="errors.has('vehicle_type') && show_errors"
              class="showError">El campo tipo de vehículo es requerido.</span>
          </div>
          <div v-if="company_invitation_form_vehicle_data && vehicular_type_location">
            <label>Número de placa *</label>
            <input
              v-model="mail.plate_number"
              type="text"
              name="plate_number"
              placeholder="Ingresa el número de placa de tu vehículo"
              v-validate="'required'">
            <span
              style="color:red; font-size:12px"
              v-show="errors.has('name') && show_errors"
              class="showError">El campo número de placa es requerido.</span>
          </div>
          
          <div>
            <label>Nombres *</label>
            <input
              v-model="mail.name"
              type="text"
              name="name"
              placeholder="Escribe tu nombre completo"
              v-validate="'required'">
            <span
              style="color:red; font-size:12px"
              v-show="errors.has('name') && show_errors"
              class="showError">El campo nombre es requerido.</span>
          </div>
          <div>
            <label>Correo electrónico *</label>
            <input
              v-model="mail.email"
              type="email"
              name="email"
              placeholder="Escribe tu correo electrónico"
              v-validate="'required|email'">
            <span
              style="color:#d30000; font-size:12px"
              v-if="invalid_email"
              class="showError">Correo electrónico inválido.</span>
          </div>
          <div>
            <label>Celular *</label>
            <input
              v-model="mail.phone"
              type="number"
              name="phone"
              placeholder="Escribe el número de contacto"
              v-validate="'required|max:14'">
            <span
              style="color:red; font-size:12px"
              v-show="errors.has('phone') && show_errors"
              class="showError">El campo debe tener un número de celular válido.</span>
          </div>
          <div v-show="show_location">
            <label>Unidad *</label>
            <select class="" name="" v-model="mail.location_id" @change="validateVehicleTypeLocation()">
              <option value="" disabled selected>Selecciona la unidad a la que te diriges</option>
              <option
                :value="location.location_id"
                v-for="(location, index) in locations"
                :key="location.location_id">{{location.location_name}}</option>
            </select>
          </div>
          <div v-if="mail.location_id && show_location == true">
            <label>Apartamento *</label>
            <select class="" name="" v-model="mail.sublocation_id">
              <option value="" disabled selected>Selecciona el apartamento</option>
              <option
                :value="sublocation.sublocation_id"
                v-for="(sublocation, index) in locations.find(element => element.location_id == mail.location_id).sublocations"
                :key="sublocation.sublocation_id">{{sublocation.sublocation_name}}</option>
            </select>
          </div>
          <div class="" style="display: flex; align-items: center; justify-content: space-between">
            <div class="access_requirement__container">
              <img :src="document_image" alt="" v-if="document_image != null">
              <label
                for="document_photo"
                class="access_requirement__button">Tomar foto de la cédula</label>
              <input
                id="document_photo"
                type="file"
                name="document_photo"
                accept="image/*;capture=camera"
                style="display:none"
                @change="createDocumentImage($event)">
              <span
                style="color:red; font-size:12px"
                v-show="errors.has('document_photo') && show_errors"
                class="showError">No has cargado ninguna imagen</span>
            </div>
            <div class="access_requirement__container">
              <img :src="selfie_image" alt="" v-if="selfie_image != null">
              <label
                for="user_photo"
                class="access_requirement__button">Tomar una selfie</label>
              <input
                id="user_photo"
                type="file"
                name="user_photo"
                accept="image/*;capture=camera"
                style="display:none"
                @change="createUserImage($event)">
              <span
                style="color:red; font-size:12px"
                v-show="errors.has('user_photo') && show_errors"
                class="showError">No has cargado ninguna imagen</span>
            </div>
          </div>
          <div class="fair_invitation__content--button_container access_requirement__button--container">
            <button
              class="access_requirement__button--principal"
              :class="{'access_requirement__button--disabled': formIsInError, 'close_button': formIsInError}"
              @click="formIsInError ? '': requestAccess()">
              Enviar
            </button>
          </div>
        </div>
      </div>
    </section>
  </article>
</template>
<script>
import { months } from 'moment'
import Loader from '@/components/shared/Loader.vue'
import VirtualGuardLogo from '@/components/shared/VirtualGuardLogo.vue'
export default {
  data(){
    return{
      show_errors: false,
      loader: false,
      mail: {
        rut: '',
        vehicle_type: '',
        plate_number: '',
        name: '',
        email: '',
        phone: '',
        location_id: '',
        document_photo: null,
        user_photo: null,
        sublocation_id: '',
        virtual_guard: true
      },
      fieldError:'',
      errorOpening: false,
      errorEnding: false,
      spacesInError: false,
      validator: '',
      selfie_image: null,
      document_image: null,
      current_company: null,
      locations: [
        {location_id: 1 , location_name: "Torre 1", sublocations:[{sublocation_id:1, sublocation_name: "Apto 101"}, {sublocation_id:2, sublocation_name:"Apto 102"}]},
        {location_id: 2, location_name:"Torre 2", sublocations:[{sublocation_id:1, sublocation_name: "Apto 201"}, {sublocation_id:2, sublocation_name:"Apto 202"}]}],
      current_location: null,
      current_sublocation: null,
      active_sublocations: false,
      open_access_request: true,
      show_location:true,
      invalid_email: false,
      vehicular_type_location: false,
      company_invitation_form_vehicle_data: false
    }
  },
  mounted() {
    this.current_company = this.$route.query.id ? this.$route.query.id : null
    // this.current_company = 6
    this.mail.location_id = this.$route.query.location_id ? Number(this.$route.query.location_id) : null
    this.mail.sublocation_id = this.$route.query.sublocation_id ? Number(this.$route.query.sublocation_id) : null
    // this.mail.location_id = 2
    // this.mail.sublocation_id = 1
    if(this.$route.query.location_id && this.$route.query.location_id){
      this.show_location=false
    }
    console.log(this.current_company);
      console.log('this.current_company',this.current_company);
    console.log(this.$route);
    console.log(this.mail);
    if(this.current_company != null && (this.mail.location_id == null || this.mail.sublocation_id == null)){
      this.open_access_request = false      
    }
    this.findLocationsByCompany()
  },
  watch:{
    current_location(){
      console.log(this.current_location);
      this.active_sublocations = true
      // this.mail.location_id = this.current_location
    },
    current_sublocation(){
      console.log(this.current_sublocation);
      // this.mail.sublocation_id = this.current_sublocation
    },
    'mail.plate_number'(newValue){
      this.mail.plate_number = this.mail.plate_number.toUpperCase();
    }
  },
  components:{
    'loader': Loader,
    'virtual-guard-logo': VirtualGuardLogo
  },
  computed:{
    formIsInError(){
      let ans=false
      let fields=[]
      if(this.mail.name.length==0){
        fields.push("'Ingresa nombre'");
      }
      if(this.mail.email.length==0){
        fields.push("'Ingresa correo electrónico'");
      }
      if(this.mail.phone.length==0){
        fields.push("'Ingresa telefono'")
      }
      if(this.show_location && (this.mail.sublocation_id == null || this.mail.sublocation_id == '')){
        fields.push("'Selecciona el apartamento al que te diriges'")
      }
      // if(!this.mail.location_id && !this.open_access_request){
      //   fields.push("'Selecciona la unidad a la que te diriges'")
      // }
      // if(!this.mail.sublocation_id && !this.open_access_request){
      //   fields.push("'Selecciona el apartamento al que te diriges'")
      // }
      if(!this.mail.document_photo){
        fields.push("'Sube la imagen de tu documento de identidad'")
      }
      if(!this.mail.user_photo){
        fields.push("'Sube una selfie'")
      }

      if(this.company_invitation_form_vehicle_data){
        if(this.mail.rut.length==0){
          fields.push("'Ingresa el número de identificación'");
        }
        if(this.vehicular_type_location && this.mail.vehicle_type.length==0){
          fields.push("'Selecciona el tipo de vehículo'");
        }
        if(this.vehicular_type_location && this.mail.plate_number.length==0){
          fields.push("'Ingresa el número de la placa'")
        }
      }

      if(fields.length>0){
        if(fields.length==2){
        this.fieldError=fields.join(" y ")
        }else{
        this.fieldError=fields.join(" , ")
        }
        this.$validator.validateAll()
        ans=true
      }else{
        this.fieldError=""
      }
      return ans
    },
    scheduleIsInError(){
      let ans=false
      if(this.errorOpening||this.errorEnding||this.spacesInError){
        ans=true
      }
      return ans
    }
  },
  methods:{
    findLocationsByCompany(){
      this.loader = true
      try {
        this.$http.get('app/company/find_locations_by_company/'+this.current_company)
        .then(function(response){
          console.log(">>>>>>>>>>>>>>>>>>>>>>>Success");
          if(this.mail.location_id === null || this.mail.sublocation_id === null){
            this.locations = response.body.ans
          }else{
            var current_location = response.body.ans.find((loc) => loc.location_id === this.mail.location_id);
            if (current_location !== undefined){
              this.vehicular_type_location = current_location.vehicular_type
            }
          }
          this.loader = false
          this.company_invitation_form_vehicle_data = response.body.company_info.invitations_form_vehicle_data
          console.log('locations', this.locations)
        }, function(response){
          console.log("<<<<<<<<<<<<<<<<<<<<<Fail");
          console.log(response.body.data)
          this.loader = false
        })
      } catch (e) {
        console.log(e.message)
        this.loader = false
      }
    },
    async createUserImage(event){
      if(event.target.files.length > 0){
        let vm = this
        let user_image = event.target.files[0]
        let extension = event.target.files[0].type.split('/')[1];
        console.log('extension', extension)

        if(extension != "jpg" && extension != "jpeg"){
          alert('Solo se admite el formato jpg o jpeg')
        }else{
          vm.selfie_image = window.URL.createObjectURL(user_image)
          const blob = await this.compressImage(user_image, parseInt(10));
          console.log('photo size', blob.size /1024)
          // alert('photo size ' + blob.size /1024)

          vm.loadUserImage(blob)
        }
      }
    },
    async createDocumentImage(event){
      if(event.target.files.length > 0){
        let vm = this
        let document_image = event.target.files[0]
        vm.document_image = window.URL.createObjectURL(document_image)
        const blob = await this.compressImage(document_image, parseInt(10));
        console.log('event size', event.target.files[0].size /1024)
        console.log('blob', blob.size /1024)
        // console.log(document_image);
        vm.loadDocumentImage(blob)
      }
    },
    loadUserImage (file) {
      let reader = new FileReader()
      let vm = this
      reader.readAsDataURL(file);
      reader.onload = function () {
        vm.mail.user_photo = reader.result
      }
    },
    loadDocumentImage (file) {
      let reader = new FileReader()
      let vm = this
      console.log(reader);
      console.log(file);
      reader.readAsDataURL(file);
      reader.onload = function () {
        vm.mail.document_photo = reader.result
        console.log('vm.mail.document_photo', vm.mail.document_photo)
      }
    },

    compressImage(imagenComoArchivo, porcentajeCalidad){

      return new Promise((resolve, reject) => {
          const $canvas = document.createElement("canvas");
          const imagen = new Image();
          let oldWidth , oldHeight , newHeight , newWidth = 1000;

          imagen.onload = () => {
            oldWidth = imagen.width;
            oldHeight = imagen.height;
            newHeight = Math.floor(oldHeight / oldWidth * newWidth)
            $canvas.width = newWidth;
            $canvas.height = newHeight;
            $canvas.getContext("2d").drawImage(imagen, 0, 0, newWidth, newHeight);
            $canvas.toBlob(
              (blob) => {
                if (blob === null) {
                  return reject(blob);
                } else {
                  resolve(blob);
                }
              },
              "image/jpeg",
              porcentajeCalidad / 100
            );
          };
          imagen.src = URL.createObjectURL(imagenComoArchivo);
        });

    },
    handleCancel(){
      this.$emit('handleCancelModalRewardUser', false)
    },
    buildDeviceId(){
      let uuid
      if(localStorage.device_id != undefined &&
         localStorage.device_id != null &&
         localStorage.device_id != '' &&
         localStorage.device_id != 'null'){
        uuid = localStorage.device_id
      } else {
        uuid = this.$uuid.v1()
        localStorage.device_id = uuid
      }
      console.log("DEVICE ID!!!!!!!!!!!!!!!!!!!!!!");
      console.log(uuid);
      this.updateDeviceId(uuid);
      return uuid
    },
    clearFields(){
      this.mail = {
        rut: '',
        vehicle_type: '',
        plate_number: '',
        name: '',
        email: '',
        phone: '',
        location_id: '',
        document_photo: null,
        user_photo: null,
        sublocation_id: '',
        virtual_guard: true
      }
      this.show_errors = false
      this.selfie_image = null
      this.document_image = null
      this.current_company = this.$route.query.id ? this.$route.query.id : null
      this.mail.location_id = this.$route.query.location_id ? Number(this.$route.query.location_id) : null
      this.mail.sublocation_id = this.$route.query.sublocation_id ? Number(this.$route.query.sublocation_id) : null
      if(this.current_company != null && (this.mail.location_id == null || this.mail.sublocation_id == null)){
        this.open_access_request = false        
      }
      this.findLocationsByCompany()
    },
    requestAccess(){
      this.loader = true
      this.invalid_email = false

      try {
        let emailRegExp = /^[\w-\.]+@([\w]+\.)+[\w]{2,4}$/
        if(!emailRegExp.test(this.mail.email)){
          this.invalid_email = true
          scrollTo(0,0)
          throw {message: "CORREO INVALIDO"}
        }

        console.log("<<<<<<<<<<<<<<<<<<<<<< Creating Access Request");
        console.log(this.mail);

        this.$http.post(this.open_access_request ? 'app/users/open_external_access_request' :'app/users/external_access_request', { data: this.encrypt({ attributes:this.mail }).toString()},)
        // this.$http.post('app/users/external_access_request', { data: this.encrypt({ attributes:this.mail }).toString()},)
        .then(function(response){
          console.log("<<<<<<<<<<<<<<<<<<<<<<<<<< Access Request Created");
          console.log(response);
          this.loader = false
          alert("Tu solicitud ha sido enviada, pronto obtendras respuesta via email o Whatsapp")
          this.clearFields()
        }, function(response){
          console.log("<<<<<<<<<<<<<<<<<<<<<<<<<< Access Request Error");
          console.log(response)
          this.loader = false
          var responseMsg = response.body.ans
          alert('Tu solicitud no pudo ser procesada, ' + responseMsg)
        })
      } catch (e) {
        this.loader = false
        console.error(e.message)
      }
    },
    validateVehicleTypeLocation(){
      if (this.locations.length > 0){
        var selected_location = this.locations.find((loc) => loc.location_id === this.mail.location_id);
        if (selected_location !== undefined){
          this.vehicular_type_location = selected_location.vehicular_type
          if (!this.vehicular_type_location){
            this.mail.rut = ''
            this.mail.vehicle_type = ''
            this.mail.plate_number = ''
          }
        }
      }
    }
  }
}
</script>
